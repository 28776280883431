import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiHttpService, ApiSingleResult } from '@capturum/api';
import { CreditNote } from '@core/interfaces/credit-note.interface';
import {
  SplitQuantityProducts,
  TotalVolumeSplitQuantity,
} from '@features/sales-order/models/split-quantity-products.model';
import { SplitOrderData } from '@core/interfaces/spli-order-data.interface';
import { map } from 'rxjs/operators';
import { SalesOrder } from '@core/models/sales-order.model';
import { MapItem } from '@capturum/auth';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable({
  providedIn: 'root',
})
export class SalesOrderService {
  public salesOrder$: BehaviorSubject<SalesOrder> = new BehaviorSubject<SalesOrder>(null);
  public salesOrderStatuses$: BehaviorSubject<MapItem[]> = new BehaviorSubject<MapItem[]>([]);

  protected endpoint = 'sales-order';

  constructor(private readonly apiHttp: ApiHttpService) {}

  public postCreditnotePDF(salesOrderId: string, data: { products: CreditNote[] }): Observable<any> {
    return this.apiHttp.post(`/credit-note`, {
      sales_order_id: salesOrderId,
      ...data,
    });
  }

  public postPalletReturn(salesOrderId: string, data: { products: CreditNote[] }): Observable<any> {
    return this.apiHttp.post(`/pallet-return`, {
      sales_order_id: salesOrderId,
      ...data,
    });
  }

  public getTotalVolumes(salesOrderId: string, body: SplitQuantityProducts): Observable<TotalVolumeSplitQuantity> {
    return this.apiHttp.post(`/${this.endpoint}/${salesOrderId}/pre-calculate-split-order`, body);
  }

  public allocateAllProducts(salesOrderId: string): Observable<void> {
    return this.apiHttp.post(`/${this.endpoint}/${salesOrderId}/bulk-allocate`, {});
  }

  public removeAllAllocations(salesOrderId: string): Observable<void> {
    return this.apiHttp.post(`/${this.endpoint}/${salesOrderId}/remove-allocations`, {});
  }

  public getEdiImportErrors(salesOrderId: string): Observable<File> {
    return this.apiHttp.get(`/${this.endpoint}/${salesOrderId}/get-edi-import-errors`, {
      responseType: 'blob',
    });
  }

  public bulkStatusUpdate(body: Record<string, Date>): Observable<object> {
    return this.apiHttp.post(`/${this.endpoint}/bulk-status-update`, body);
  }

  public getTotal(salesOrderId: string): Observable<
    ApiSingleResult<{
      products: Record<string, string>;
      costs: Record<string, string>;
    }>
  > {
    return this.apiHttp.get(`/${this.endpoint}/${salesOrderId}/summary`);
  }

  public sendFiton(salesOrderId: string): Observable<any> {
    return this.apiHttp.get(`/${this.endpoint}/${salesOrderId}/fiton-export`);
  }

  public splitOrder(salesOrderId: string, products: Partial<SplitOrderData>[]): Observable<any> {
    return this.apiHttp.post(`/${this.endpoint}/${salesOrderId}/split-order`, {
      products,
    });
  }

  public bulkStoreProducts(
    id: string,
    data: { product_id: string; quantity: string; sales_price: string }[],
  ): Observable<any> {
    return this.apiHttp.post(`/${this.endpoint}/${id}/bulk-store-products`, data);
  }

  public getSalesOrderById(id: string): Observable<SalesOrder> {
    return this.apiHttp.get<ApiSingleResult<SalesOrder>>(`/${this.endpoint}/${id}`).pipe(
      map((result) => {
        return result.data;
      }),
    );
  }

  public updatePrice(salesOrderId: string): Observable<any> {
    return this.apiHttp.post(`/${this.endpoint}/${salesOrderId}/update-prices `, {});
  }

  public sendToPrinter(salesOrderId: string, url: string): Observable<any> {
    return this.apiHttp.get(`/${this.endpoint}/${salesOrderId}/${url}`, {});
  }

  public downloadSalesOrders(url: string, body: { [key: string]: any }): Observable<Blob> {
    return this.apiHttp.post(url, body, { responseType: 'blob' });
  }

  public printCmr(url: string, body: { [key: string]: any }): Observable<any> {
    return this.apiHttp.post(url, body);
  }
}
